var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"dashboard-container px-sm-10 px-3",attrs:{"fluid":""}},[(_vm.isLoaded)?_c('PageHeader',{attrs:{"header-text":_vm.dashboard.name,"outlined-button-text":"Manage Dashboard","regular-button-text":_vm.canEditDashboard(_vm.dashboard) ? 'Edit Dashboard' : null},on:{"outlined-button-click":function($event){return _vm.$router.push({ name: 'DashboardList' })},"regular-button-click":function($event){return _vm.$router.push({ name: 'EditDashboard', params: { id: _vm.dashboard.id } })}},scopedSlots:_vm._u([{key:"subheader",fn:function(){return [(_vm.isLoaded && _vm.isDefaultDashboard)?_c('p',{staticClass:"dashboard-sub-headline"},[_vm._v(" Currently viewing your "),_c('span',[_vm._v("Default")]),_vm._v(" dashboard ")]):_vm._e()]},proxy:true}],null,false,3288332674)}):_vm._e(),(!_vm.isLoaded)?_c('div',{staticClass:"skeleton-header"},[_c('skeleton-loader-vue',{attrs:{"type":"rect","width":600,"height":40,"radius":4,"rounded":"","animation":"fade"}})],1):_vm._e(),_c('v-container',{staticClass:"pb-15",staticStyle:{"position":"relative"},style:({
      'height': _vm.dynamic_height ? _vm.dynamic_height + 'px' : '585px'
    }),attrs:{"fluid":""}},[_c('div',{staticClass:"grid-stack grid-stack-4",staticStyle:{"z-index":"7"},attrs:{"id":"advanced-grid"}},_vm._l((_vm.dashboard_widgets),function(dashboard_widget){return _c('div',{key:'dashboard-widget-' + dashboard_widget.id,class:{
          'grid-stack-item': true,
          'ui-draggable-disabled': true,
          'ui-resizable-disabled': true,
          'd-none': _vm.loading_grid
        },attrs:{"id":'dashboard-widget-' + dashboard_widget.id,"gs-x":dashboard_widget.xPosition,"gs-y":dashboard_widget.yPosition,"gs-w":dashboard_widget.width,"gs-h":dashboard_widget.height}},[_c('div',{staticClass:"grid-stack-item-content"},[(dashboard_widget.data)?_c(dashboard_widget.component,{tag:"component",attrs:{"config":dashboard_widget,"data":dashboard_widget.data}}):_c('app-widget-block',{attrs:{"config":dashboard_widget}},[_c('div',{staticClass:"skeleton-widget-content"},[_c('skeleton-loader-vue',{attrs:{"type":"rect","width":120,"height":52,"radius":4,"rounded":"","animation":"fade"}})],1)])],1)])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }